
@import "../../variables.less";
@import "../../resource/common.less";

@currentWidth: 600px;
@attachWidth: 150px;
.body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: @bigSpace;
}
@import "/static/plugin/iview/iview.css";
@font-face {
  font-family: "Phage Regular";
  src: url("/static/fonts/Phage Regular.ttf"), url("/static/fonts/Phage Regular.ttf"); /* IE9+ */
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  font-family: "微软雅黑";
}

.login {
  background: radial-gradient(
    closest-side,
    rgba(110, 115, 129, 0.4),
    rgba(255, 255, 255, 0)
  );
  height: 100vh;
  width: 32%;
  float: right;
  box-sizing: border-box;
  font-size: 24px;
  right: 4%;
}

.title {
  text-transform: uppercase;
  font-size: 38px;
  color: #fff;
  margin-top: 0;
  font-weight: 600;
  letter-spacing: 3px;
  background-color: transparent;
}

.submit {
  background: #00a29a;
  border: none;
  margin-top: 40px;
  height: 66px;
  border-radius: 12px;
  text-align: center;
  font-size: 26px;
  color: white;
  line-height: 60px;
}

.content {
  width: 100%;
  background: url("../../assets/img/loginBg1.jpg");
  background-size: 100% 100%;
  font-family: "微软雅黑";
}

.content_right {
  width: 68%;
  float: left;
  box-sizing: border-box;
  height: 100vh;
}

.content_left {
  width: 500px;
  margin: calc(50% - 58px) auto 0;
}
@media screen and (max-width: 1600px) {
  .content_left {
    width: 100%;
  }
}

.content_box {
  position: absolute;
  top: 50%;
  transform: translateY(-57%);
  left: 7%;
  height: auto;
}

.titleBg {
  font-weight: 600;
  font-size: 86px;
  color: white;
  letter-spacing: 10px;
  text-shadow: 3px 2px 2px #282828;
}

.subTitle {
  letter-spacing: 13px;
  font-size: 46px;
  color: white;
  text-shadow: 3px 2px 2px #282828;
}

.inputWrap {
  width: 100%;
  background: rgba(255, 255, 255, 0.9) !important;
  border: 0;
  outline: none;
  height: 60px;
  padding-left: 60px;
  font-size: 24px;
  color: #a7a7a7;
}

::-webkit-input-placeholder {
  color: #a7a7a7;
  font-size: 20px;
  /*padding-left: 35px;*/
}
.ivu-form-item {
  margin-bottom: 0;
}
.inputIcon_name {
  position: absolute;
  left: 20px;
  background: url("/static/img/name.png") no-repeat center center;
  background-size: 100%;
  height: 60px;
  width: 20px;
}
.inputIcon_pwd {
  position: absolute;
  left: 20px;
  background: url("/static/img/pwd.png") no-repeat center center;
  background-size: 100%;
  height: 60px;
  width: 20px;
}
.footer_content {
  width: 100%;
  height: auto;
  margin-top: 26px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.footer_content:after {
  // display: block;
  content: "";
  clear: both;
}
.login_ {
  float: left;
  cursor: pointer;
  display: inline-block;
  color: white;
}
.loginIcon {
  position: relative;
  top: 4px;
  background: white;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 15px;
}
.removePwd {
  display: inline-block;
  float: right;
  color: #00a29a;
  cursor: pointer;
}
@media screen and (max-width: 1714px) {
  .titleBg {
    font-size: 64px;
  }
}
.inputWrap:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f0f1f2 inset !important;
  -webkit-text-fill-color: #a7a7a7 !important;
}
/*ipad 适配*/
@media screen and (max-width: 1112px) {
  .inputIcon_name {
    height: 40px;
    width: 14px;
  }
  .content_left {
    margin-top: 18vh;
  }
  .inputIcon_pwd {
    height: 40px;
    width: 14px;
  }
  .titleBg {
    font-size: 46px;
    margin-bottom: 0 !important;
  }
  .subTitle {
    font-size: 28px;
  }
  .title {
    font-size: 28px;
    margin-bottom: 30px !important;
  }
  .login {
    font-size: 14px;
  }
  .loginIcon {
    height: 16px;
    width: 16px;
  }
  .inputWrap {
    height: 40px;
    font-size: 14px;
  }
  .submit {
    height: 40px;
    line-height: 40px;
    font-size: 17px;
  }
  .content_box {
    left: 2%;
  }
}
.ivu-form-item {
  margin-bottom: 0;
}
input {
  border: none;
  color: #000;
}

* {
  position: relative;
}
html,
body {
  height: 100%;
}
.infoContent {
  font-size: 0;
  width: 100%;
}
.loginType {
  color: black;
  background: white;
  padding: 20px 30px;
  font-size: 24px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.loginType:last-child:before {
  content: "";
  position: absolute;
  height: 36px;
  width: 2px;
  background: #eee;
  left: 0;
}
.loginType.active {
//   color: dodgerblue;
}
.loginType.active:after {
  content: "";
  display: block;
  position: absolute;
  height: 3px;
  width: 88%;
//   background: dodgerblue;
    background: #00a29a;
  margin: 0 auto;
  left: 30px;
  bottom: 1px;
}
.loginForm {
  padding: 0 30px;
  width: 100%;
  background: white;
}
input {
  border: none;
  width: 100%;
  height: 60px;
}
.loginFoot {
  height: 30px;
  background: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.content {
  width: 100%;
  background-size: cover;
  font-family: "微软雅黑";
  height: 100%;
}
hr {
  background: #eee;
}
input {
  padding-left: 35px;
}
input:focus {
  outline: none;
}
:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
