@import url(/static/plugin/iview/iview.css);
.mainContent[data-v-77846979] {
  width: 600px;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px;
  pointer-events: all;
  -webkit-transition: right 2s ease;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
}
.mainContent .head[data-v-77846979] {
  line-height: 32px;
  font-size: 18px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  color: #FFF;
}
.mainContent .head .attach[data-v-77846979] {
  width: 150px;
  float: right;
}
.mainContent .blockTitle[data-v-77846979] {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.left_click_btn[data-v-77846979] {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img[data-v-77846979] {
  width: 18px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.left_click_btn_active[data-v-77846979] {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
.left_click_btn_active img[data-v-77846979] {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.mainContent2[data-v-77846979],
.index[data-v-77846979] {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 750px;
  background: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  -webkit-transition: right 1s ease;
  transition: right 1s ease;
}
.mainContent2 .mian_title[data-v-77846979],
.index .mian_title[data-v-77846979] {
  font-size: 18px;
  width: 100%;
  padding: 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  position: relative;
  margin: 0 0 0 10px;
  line-height: 18px;
}
.mainContent2 .mian_title[data-v-77846979]:before,
.index .mian_title[data-v-77846979]:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 11px;
  background: #4261ED;
  left: 1px;
}
.mainContent2 .gary_line[data-v-77846979],
.index .gary_line[data-v-77846979] {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mainContent2 .gary_line[data-v-77846979]::after,
.index .gary_line[data-v-77846979]::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mainContent2 .gary_line[data-v-77846979]::before,
.index .gary_line[data-v-77846979]::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.body[data-v-77846979] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 12px;
}
@font-face {
  font-family: "Phage Regular";
  src: url("/static/fonts/Phage Regular.ttf"), url("/static/fonts/Phage Regular.ttf");
  /* IE9+ */
}
.content[data-v-77846979] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  font-family: "微软雅黑";
}
.login[data-v-77846979] {
  background: radial-gradient(closest-side, rgba(110, 115, 129, 0.4), rgba(255, 255, 255, 0));
  height: 100vh;
  width: 32%;
  float: right;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 24px;
  right: 4%;
}
.title[data-v-77846979] {
  text-transform: uppercase;
  font-size: 38px;
  color: #fff;
  margin-top: 0;
  font-weight: 600;
  letter-spacing: 3px;
  background-color: transparent;
}
.submit[data-v-77846979] {
  background: #00a29a;
  border: none;
  margin-top: 40px;
  height: 66px;
  border-radius: 12px;
  text-align: center;
  font-size: 26px;
  color: white;
  line-height: 60px;
}
.content[data-v-77846979] {
  width: 100%;
  background: url(../img/loginBg1.222e7cae.jpg);
  background-size: 100% 100%;
  font-family: "微软雅黑";
}
.content_right[data-v-77846979] {
  width: 68%;
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100vh;
}
.content_left[data-v-77846979] {
  width: 500px;
  margin: calc(50% - 58px) auto 0;
}
@media screen and (max-width: 1600px) {
.content_left[data-v-77846979] {
    width: 100%;
}
}
.content_box[data-v-77846979] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-57%);
          transform: translateY(-57%);
  left: 7%;
  height: auto;
}
.titleBg[data-v-77846979] {
  font-weight: 600;
  font-size: 86px;
  color: white;
  letter-spacing: 10px;
  text-shadow: 3px 2px 2px #282828;
}
.subTitle[data-v-77846979] {
  letter-spacing: 13px;
  font-size: 46px;
  color: white;
  text-shadow: 3px 2px 2px #282828;
}
.inputWrap[data-v-77846979] {
  width: 100%;
  background: rgba(255, 255, 255, 0.9) !important;
  border: 0;
  outline: none;
  height: 60px;
  padding-left: 60px;
  font-size: 24px;
  color: #a7a7a7;
}
[data-v-77846979]::-webkit-input-placeholder {
  color: #a7a7a7;
  font-size: 20px;
  /*padding-left: 35px;*/
}
.ivu-form-item[data-v-77846979] {
  margin-bottom: 0;
}
.inputIcon_name[data-v-77846979] {
  position: absolute;
  left: 20px;
  background: url("/static/img/name.png") no-repeat center center;
  background-size: 100%;
  height: 60px;
  width: 20px;
}
.inputIcon_pwd[data-v-77846979] {
  position: absolute;
  left: 20px;
  background: url("/static/img/pwd.png") no-repeat center center;
  background-size: 100%;
  height: 60px;
  width: 20px;
}
.footer_content[data-v-77846979] {
  width: 100%;
  height: auto;
  margin-top: 26px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.footer_content[data-v-77846979]:after {
  content: "";
  clear: both;
}
.login_[data-v-77846979] {
  float: left;
  cursor: pointer;
  display: inline-block;
  color: white;
}
.loginIcon[data-v-77846979] {
  position: relative;
  top: 4px;
  background: white;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 15px;
}
.removePwd[data-v-77846979] {
  display: inline-block;
  float: right;
  color: #00a29a;
  cursor: pointer;
}
@media screen and (max-width: 1714px) {
.titleBg[data-v-77846979] {
    font-size: 64px;
}
}
.inputWrap[data-v-77846979]:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f0f1f2 inset !important;
  -webkit-text-fill-color: #a7a7a7 !important;
}
/*ipad 适配*/
@media screen and (max-width: 1112px) {
.inputIcon_name[data-v-77846979] {
    height: 40px;
    width: 14px;
}
.content_left[data-v-77846979] {
    margin-top: 18vh;
}
.inputIcon_pwd[data-v-77846979] {
    height: 40px;
    width: 14px;
}
.titleBg[data-v-77846979] {
    font-size: 46px;
    margin-bottom: 0 !important;
}
.subTitle[data-v-77846979] {
    font-size: 28px;
}
.title[data-v-77846979] {
    font-size: 28px;
    margin-bottom: 30px !important;
}
.login[data-v-77846979] {
    font-size: 14px;
}
.loginIcon[data-v-77846979] {
    height: 16px;
    width: 16px;
}
.inputWrap[data-v-77846979] {
    height: 40px;
    font-size: 14px;
}
.submit[data-v-77846979] {
    height: 40px;
    line-height: 40px;
    font-size: 17px;
}
.content_box[data-v-77846979] {
    left: 2%;
}
}
.ivu-form-item[data-v-77846979] {
  margin-bottom: 0;
}
input[data-v-77846979] {
  border: none;
  color: #000;
}
*[data-v-77846979] {
  position: relative;
}
html[data-v-77846979],
body[data-v-77846979] {
  height: 100%;
}
.infoContent[data-v-77846979] {
  font-size: 0;
  width: 100%;
}
.loginType[data-v-77846979] {
  color: black;
  background: white;
  padding: 20px 30px;
  font-size: 24px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.loginType[data-v-77846979]:last-child:before {
  content: "";
  position: absolute;
  height: 36px;
  width: 2px;
  background: #eee;
  left: 0;
}
.loginType.active[data-v-77846979]:after {
  content: "";
  display: block;
  position: absolute;
  height: 3px;
  width: 88%;
  background: #00a29a;
  margin: 0 auto;
  left: 30px;
  bottom: 1px;
}
.loginForm[data-v-77846979] {
  padding: 0 30px;
  width: 100%;
  background: white;
}
input[data-v-77846979] {
  border: none;
  width: 100%;
  height: 60px;
}
.loginFoot[data-v-77846979] {
  height: 30px;
  background: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.content[data-v-77846979] {
  width: 100%;
  background-size: cover;
  font-family: "微软雅黑";
  height: 100%;
}
hr[data-v-77846979] {
  background: #eee;
}
input[data-v-77846979] {
  padding-left: 35px;
}
input[data-v-77846979]:focus {
  outline: none;
}
[data-v-77846979]:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}


/*# sourceMappingURL=chunk-1d027dbd.af7c73e1.css.map*/